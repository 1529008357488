#contactos-div{
    display: flex;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2%;
    margin-bottom: 20px;
}

.row-contactos{
    display: flex;
    align-items: center;
}

#contactos-text{
    font-size:calc(12px + 1.1vw);
}

.icons-contactos{
    display: flex;
    justify-content: flex-end;
}
#with-mb{
    margin-bottom: 15px;
}

#background-form{
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url("../../assets/quinta_das_fontes-135.jpg");
    background-size: cover;
}
#enviar-msg-container{
    padding: 2%;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 10% !important;
    margin-right: 10% !important;

    border: 2px solid black;
    border-radius: 10px;
    background-color: rgb(255, 255, 255, 0.8);
    
}

#title-enviar-msg {
    font-family: Open sans;
    margin-bottom: 5%;
    margin-top: 5%;
    padding-left: calc(var(--bs-gutter-x)*.5);
    text-align: center;
}

.row-iframe{
    margin-top: 3%;
    margin-bottom: 3%;
}

#input-form {
    border: 0.5px solid black;
    border-radius: 5px;
    width: 100%;
}

.msg-enviar-msg {
    height: 150px;
}

.row-btn-enviar-msg{
    display: flex;
    justify-content: center;
}

.btn-enviar-msg {
    align-items: center;
    background-color: transparent;
    border: 1.5px solid black;
    color: black;
    font-size: 12px;
    height: 40px;
    width: 40%;
}

@media only screen and (max-width: 768px) {
    .enviar-msg-container{
        margin-top: 0px;
        margin-left: 3% !important;
        margin-right: 3% !important;
        border: none;
    }

    .row-btn-enviar-msg {
        margin-bottom: 45px;
    } 
}