.photos-grid-row{
    height: 400px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 10%;
    padding-right: 10%;
}

.photos-grid-col{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;   
}

#photos-grid-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .2s ease-in-out;
}

#photos-grid-img:hover{
    height: 115%;
    width: 115%;
}

.photos-grid-btn-row{
    display: flex;
    justify-content: center;
}

.photos-grid-text-href{
    width: 150px;
    padding-left: 0px;
    padding-right: 0px;
}

.photos-grid-btn-more{
    color: black;
    background-color: transparent;
    border-radius: 80px;
    width: 150px;
    height: 40px;
    margin-top: 40px;
    border: 2px solid black;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Mulish', sans-serif;
}

.photos-grid-btn-more:hover{
    background-color: black;
    color: white;
}

@media only screen and (max-width: 980px) {
    .photos-grid-row{
        height: 600px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .photos-grid-col{
        height: 200px;
    }
}