.history-text-title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: #f3f0ef;
    padding-top: 2%;
}

.history-title{
    font-size: 2.5vw;
    font-weight: 600;
    font-family: 'Playfair Display';
    text-align: center;
    width: 85%;
}

.history-subtitle{
    margin-top: 2%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 1.5vw;
    font-weight: 500;
    font-family: "Work Sans",sans-serif;
    text-align: justify;
    margin-bottom: 2%
}

.history-subtitle.withPhotos{
    padding: 0;
}

.history-fotos{
    display: table;
    width: 100%;
    height: 500px;

    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 20%;
    padding-right: 20%;
    
    overflow: hidden;
}

#fotos-table-cell{
    display: table-cell;
}

.history-text-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #f3f0ef;
}

.history-fotos-col.right{
    padding-right: 0%;
}

.history-fotos-col.esq{
    padding-right: 0%;
}

.history-fotos-col.down{
    padding-bottom: 0%;
}

.history-fotos-col.up{
    padding-top: 2%;
}

#photos-col-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.div-grid{
    display: grid;
    grid-template-columns:  repeat(2, 1fr);
    gap: 10px;
   
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20%;
    padding-right: 20%;
}

.item-all-height{
    grid-row-start: 1;
    grid-row-end: 3;
}

.item-all-height.with-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    background-color: #f3f0ef;
    grid-row-start: 1;
    grid-row-end: 4;
}

.on-right{
    grid-column-start: 2;
    grid-column-end: 3;
}

#grid-text-imgs{
    grid-row-start: 1;
    grid-row-end: 4;
}


@media only screen and (max-width: 900px) {

    .history-title{
        font-size: 2.5vh;
        width: 95%;
    }

    .history-subtitle{
        font-size: 2.2vh;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media only screen and (max-width: 1080px) {
    .div-grid{
        padding-left: 8%;
        padding-right: 8%;
    }

    #grid-text-imgs{
        padding-left: 1%;
        padding-right: 1%;
    }
}    