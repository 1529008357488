.css-1fzpoyk{
  img {
    object-fit: cover !important;
    max-height: none !important;
    height: 400px;
    width: 600px;
  }
}

.css-1qzevvg {
    position: relative;
    display: flex;
    justify-content: center !important;
    height: 20px !important;
    margin: 5px 0 0 !important;
    width: 100% !important;
    
    img {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
}

.cardEvent {
    display: flex;
    flex-direction: column;
    background-color: transparent;

    p{
      text-align: center;
      font-size: 1.5vw;
      font-weight: 500;
      font-family: "Work Sans", sans-serif;
      margin-top: 5px;
    }
}
  