.video-container{
    height: 100vh;
    width: 100%;
    position: relative;
}

.over-opacity-video,
.video-div {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
}

.over-opacity-video{
    z-index: 10;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.video-text-title{
    color: white;
    font-size: 55px;
    font-family: 'Playfair Display';
    text-align: center;
}

.video-text-subtitle{
    color: white;
    font-size: 18px;
    font-family: 'Mulish', sans-serif;
    text-align: center;
    margin-top: -20px;
}

#video-hp{
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.active{
    display: inline-block;
}

.inactive{
    display: none;
}

@media only screen and (max-width: 780px) {
    .video-container{
        height: 550px;
    }

    #video-hp{
        height: 550px;
    }
}