@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.header-web{
    position: absolute;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    height: 100px;
    width: 100%;
    padding-right: 2%;
}

.header-web.fixed{
    position: fixed;
    background-color: white;
    box-shadow: 0 -10px 30px rgba(0,0,0,.3);
}

#header-options{
    display: flex;
    justify-content: center;
}

#header-options-2{
    display: flex;
    justify-content: flex-end;
}

.row-main-options{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.link-navbar{
    width: auto;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-size: 1vw;
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    padding-left: 0%;
    padding-right: 0%;
}

.link-navbar:hover{
    color: white;
    font-size: 1.1vw;
    cursor: pointer;
}

.link-navbar.fixed{
    color: black;
}

.link-navbar.fixed:hover{
    color: black;
    font-size: 18px;
    cursor: pointer;
}

.header-btn-more{
    background-color: white;
    border-radius: 80px;
    width: 10vw;
    height: 2.6vw;
    border: 2px solid white;
    font-size: 0.8vw;
    color: black;
    font-weight: bold;
    font-family: 'Mulish', sans-serif;
    text-transform: uppercase;
}

.header-btn-more.fixed{
    border: 2px solid black;
}

#header-mobile{
    display: none;
    align-items: center;
    justify-content: space-around;
    height: 110px;
    background-color: #F7F7F7;
}

.header-mobile-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-icon-burguer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-title{
    font-size: 32px;
    color: #0A512C;
}

#link-sidebar{
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 30px;
    font-family: 'Mulish', sans-serif;
    line-height: 55px;
}

.sidebar-col-arrow{
    display: flex;
    align-items: center;
}

#img-header-mobile{
    cursor: pointer;
    width: auto;
}


@media only screen and (max-width: 1024px) {
    #header-mobile{
        display: flex;
    }

    #header-inDesktop{
        display: none;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){
    .link-navbar{
        font-size: 1.2vw;
    }
}


@media only screen and (max-width: 780px) {
    .menu-sidebar{
        width: 70% !important;
    }
}

@media only screen and (max-width: 759px) {
    .menu-sidebar{
        width: 90% !important;
    }
}

@media only screen and (min-width: 760px) and (max-width: 1080px) {
    .menu-sidebar{
        width: 60% !important;
    }
}

@media only screen and (min-width: 1081px) and (max-width: 1514px) {
    .menu-sidebar{
        width: 45% !important;
    }
}

@media only screen and (min-width: 1515px) {
    .menu-sidebar{
        width: 30% !important;
    }
}

@media only screen and (max-width: 520px) {
    #link-sidebar{
        font-size: 5vw;
    }
}