.lazer-row-title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    min-height: 100px;
    background-color: #f3f0ef;
}

.lazer-title{
    font-size: 50px;
    font-weight: 600;
    font-family: 'Playfair Display';
    text-align: center;
}

.lazer-locais-fotos{
    margin-top: 20px;
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 50px;
}

#lazer-fotos-space{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;   
    height: 400px;
}

#lazer-fotos-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .2s ease-in-out;
}

#lazer-fotos-img:hover{
    height: 115%;
    width: 115%;
}

.lazer-locais-description{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 35px;
    color: black;
    font-family: "Work Sans",sans-serif;
    font-weight: 600;
}

.lazer-locais-link{
    width: auto;
    color: black;
    text-decoration: none;
    padding-left: 0;
    padding-right: 0;
}

#lazer-fotos-cultura-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.lazer-cultura-fotos{
    margin-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
    overflow: hidden;
}

.lazer-cultura-fotos.sem-espaco{
    margin-top: 0px;
    margin-bottom: 50px;
}

.lazer-cultura-foto-col.cultura-text{
    background-color: #f7f7f7;
    padding: 2%;
}

.lazer-cultura-foto-col{
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.lazer-cultura-text{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size:calc(25px + 1vw);
    color: black;
    font-family: "Work Sans",sans-serif;
    font-weight: 600;
    text-align: center;
}

#col-icon-maps{
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 766px) {

    .lazer-locais-fotos{
        padding-left: 7%;
        padding-right: 7%;
        row-gap: 50px;
    }
    .lazer-cultura-fotos{
        padding-left: 7%;
        padding-right: 7%;
    }

    .lazer-cultura-foto-col.cultura-text{
        height: 100px;
    }

    .lazer-cultura-fotos.sem-espaco{
        margin-top: 40px;
        margin-bottom: 50px;
    }

}