.lastEvent-text-title{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: #f3f0ef;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 40px;
}

.lastEvent-text-subtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
}

.lastEvent-title{
    font-size: 2.7vw;
    font-weight: 600;
    font-family: 'Playfair Display';
    text-align: center;
    width: 85%;
}


.lastEvent-subtitle{
    font-size: 2.3vw;
    font-weight: 500;
    font-family: "Work Sans",sans-serif;
    text-align: center;
}

.paddingHome{
    padding-left: 15% !important;
    padding-right: 15% !important;
}

.heightHome{
    height: 450px;
}

@media only screen and (max-width: 980px) {

    .paddingHome{
        height: 1250px;
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .heightHome{
        height: 300px;
    }

    .lastEvent-text-subtitle{
        margin-top: 20px;
    }
    
}

@media only screen and (max-width: 900px) {

    .lastEvent-title{
        font-size: 3vh;
        width: 95%;
    }

    .lastEvent-subtitle{
        font-size: 2.7vh;
        padding-left: 10%;
        padding-right: 10%;
    }
}